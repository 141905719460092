.taplink-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0;
  color: #000000;
  font-size: 1.5rem;
  text-decoration: none;
}