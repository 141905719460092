.avatar {
  position: absolute;
  top: -3rem;
  left: 2rem;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}