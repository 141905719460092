.taplink {
  position: relative;
  width: 40rem;
  padding: 2rem;
  box-shadow: 2px 2px 10px rgba(2, 34, 34, 0.12);
  border-radius: 0.5rem;
  background-color: #ffffff;
}

@media screen and (max-width: 768px) {
  .taplink {
    width: 90vw;
  }
}